// TODO WL: These all need moving to the styles in app root and the path changing in angular.json

@import 'variables/index.scss';
@import 'mixins/index.scss';

@import 'utils/display.scss';

@import 'base/base.scss';
@import 'base/icons.scss';
@import 'base/type.scss';
@import 'base/buttons.scss';
@import 'base/checkboxes.scss';
@import 'base/legacy-formbuilder';
@import 'base/backgrounds.scss';

@import 'layout/grid.scss';
@import 'layout/flex.scss';

@import 'storybook/pages/heating.scss';

app-root {
  p,
  strong {
    color: var(--tone-700);
  }
}

.plan-info {
  border-top: 2px solid var(--accent);
  text-align: left;
  background: var(--tone-200);
  padding: 15px;
  position: relative;

  &__image {
    max-width: 200px;
    margin: 20px auto;
  }
}

.media {
  display: flex;
  flex: 1;

  @include media-breakpoint-up(lg) {
    margin: 0 9px;
  }

  &-body {
    flex: 1;
  }
}

.root,
storybook-dynamic-app-root,
mat-dialog-container {
  display: block;
  height: 100%;
}

.full-page {
  min-height: 100vh;
}

@media print {
  .theme-blue,
  .footer,
  .btn,
  .printed,
  .edit-link,
  img {
    display: none !important;
  }
}

.custom-alert-dialog-container-error {
  overflow-y: auto;
  height: unset !important;
  .mat-dialog-container {
    border-top: 4px solid var(--error-600);
  }
}

.custom-alert-dialog-container-info {
  overflow-y: auto;
  height: unset !important;
  .mat-dialog-container {
    border-top: 4px solid var(--primary-500);
  }
}

.custom-alert-dialog-container-success {
  overflow-y: auto;
  height: unset !important;
  .mat-dialog-container {
    border-top: 4px solid var(--success-600);
  }
}

.custom-alert-dialog-container-warning {
  overflow-y: auto;
  height: unset !important;
  .mat-dialog-container {
    border-top: 4px solid var(--decoration-color-1);
  }
}

.unsupport-browser-width {
  mat-dialog-container {
    width: 550px;
  }
}
